import { useEffect } from 'react';

import { useRouter } from 'next/router';

import Trans from '@components/Trans';
import CustomLink from '@components/global/CustomLink';
import { Button } from '@components/ui/design2023/Button';

import { laravelRouteSolutionsSellingKnowledge } from '@static_components/laravelLinks';
import clsx from 'clsx';

import styles from './CoreOfferBanner.module.css';

function CoreOfferBanner() {
    const { locale } = useRouter();
    useEffect(() => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({ event: 'core_offer_banner_viewed', placement: 'Sticky header' });
    }, []);

    const handleClick = () => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({ event: 'core_offer_banner_clicked', placement: 'Sticky header' });
    };

    return (
        <div className={styles.coreOfferBanner}>
            <Trans
                i18nKey="coreOfferBannerTitle"
                ns="pages/homepage/coreofferbanner/index"
                components={{
                    p: <p />,
                    br: <br />,
                    strong: <strong />,
                }}
            />
            <Button
                className={clsx(styles.button, styles.arrow)}
                color="ghost"
                label={
                    <Trans
                        i18nKey="coreOfferBannerCTA"
                        ns="pages/homepage/coreofferbanner/index"
                    />
                }
                onClick={handleClick}
                customLink={<CustomLink href={{ pathname: 'solutions/selling-knowledge' }} />}
                href={laravelRouteSolutionsSellingKnowledge(locale)}
            />
        </div>
    );
}

export default CoreOfferBanner;
