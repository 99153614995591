import { multisiteRouteCustomersPostBeautysleep, multisiteRouteCustomersPostInfoshareacademy, multisiteRouteCustomersPostMondayGroup } from '@static_components/multisiteLinks';

import React from 'react';
import SVGBeautysleep from './assets/beautysleep.svg';
import SVGInfoshareAcedemy from './assets/infoshare-academy.svg';
import SVGMonday from './assets/monday.svg';
import Testimonial from './Testimonial';
import Trans from '@components/Trans';
import TransText from 'next-translate/TransText';
import Underline from '@components/ui/Underline';
import styles from './WhyGetresponse.module.css';
import { useRouter } from 'next/router';
import useTranslation from '@hooks/useTranslation';

interface WhyGetresponseProps {
    customers: Array<{
        title: string;
        url: string;
        image: {
            url: string;
            alt?: string;
        };
        description?: string;
    }>;
}

const WhyGetresponse = ({ customers }: WhyGetresponseProps) => {
    const { t } = useTranslation('pages/homepage/2024/v1/index');
    const { locale } = useRouter();
    return (
        <section className={styles.whyGetresponse}>
            <h2 className={styles.header}>
                <Trans
                    i18nKey="whyGetresponseHeader"
                    components={{
                        u: <span />,
                    }}
                    ns="pages/homepage/2024/v1/index"
                />
            </h2>
            <ul className={styles.numbers}>
                <li>
                    <em>
                        <Underline>
                            <span dangerouslySetInnerHTML={{ __html: t('whyGetresponseNumber1') }} />
                        </Underline>
                    </em>
                    <p dangerouslySetInnerHTML={{ __html: t('whyGetresponseText1') }} />
                </li>
                <li>
                    <em>
                        <Underline>
                            <span dangerouslySetInnerHTML={{ __html: t('whyGetresponseNumber2') }} />
                        </Underline>
                    </em>
                    <p dangerouslySetInnerHTML={{ __html: t('whyGetresponseText2') }} />
                </li>
                <li>
                    <em>
                        <Underline>
                            <span dangerouslySetInnerHTML={{ __html: t('whyGetresponseNumber3_v_b') }} />
                        </Underline>
                    </em>
                    <p dangerouslySetInnerHTML={{ __html: t('whyGetresponseText3') }} />
                </li>
                <li>
                    <em>
                        <Underline>
                            <span dangerouslySetInnerHTML={{ __html: t('whyGetresponseNumber4') }} />
                        </Underline>
                    </em>
                    <p dangerouslySetInnerHTML={{ __html: t('whyGetresponseText4') }} />
                </li>
            </ul>
            {customers.length ? (
                <div className={styles.testimonials}>
                    {customers.map((customer, index) => {
                        return (
                            <Testimonial
                                key={index}
                                title={
                                    <TransText
                                        text={customer.title}
                                        components={{
                                            span: <span />,
                                            u: <span />,
                                            br: <br />,
                                        }}
                                    />
                                }
                                quote={
                                    <TransText
                                        text={customer.description ?? ''}
                                        components={{
                                            strong: <strong />,
                                            b: <b />,
                                            br: <br />,
                                        }}
                                    />
                                }
                                logo={
                                    <img
                                        loading="lazy"
                                        alt={customer.image?.alt ?? ''}
                                        src={customer.image.url}
                                    />
                                }
                                link={customer.url}
                                linkLabel={t('caseStudyButton')}
                            />
                        );
                    })}
                </div>
            ) : (
                <div className={styles.testimonials}>
                    <Testimonial
                        title={
                            <Trans
                                i18nKey="testimonialHeader1"
                                components={{
                                    u: <span />,
                                }}
                                ns="pages/homepage/2024/v1/index"
                            />
                        }
                        quote={t('testimonialQuote1')}
                        logo={
                            <img
                                loading="lazy"
                                alt="infoShare Academy logo"
                                src={SVGInfoshareAcedemy.src}
                                width={SVGInfoshareAcedemy.width}
                                height={SVGInfoshareAcedemy.height}
                            />
                        }
                        link={multisiteRouteCustomersPostInfoshareacademy(locale)}
                        linkLabel={t('caseStudyButton')}
                    />
                    <Testimonial
                        title={
                            <Trans
                                i18nKey="testimonialHeader3_v_b"
                                components={{
                                    u: <span />,
                                }}
                                ns="pages/homepage/2024/v1/index"
                            />
                        }
                        quote={t('testimonialQuote3_v_b')}
                        logo={
                            <img
                                loading="lazy"
                                alt="infoShare Academy logo"
                                src={SVGMonday.src}
                                width={SVGMonday.width}
                                height={SVGMonday.height}
                            />
                        }
                        link={multisiteRouteCustomersPostMondayGroup(locale)}
                        linkLabel={t('caseStudyButton')}
                    />
                    <Testimonial
                        title={
                            <Trans
                                i18nKey="testimonialHeader2"
                                components={{
                                    u: <span />,
                                }}
                                ns="pages/homepage/2024/v1/index"
                            />
                        }
                        quote={t('testimonialQuote2')}
                        logo={
                            <img
                                loading="lazy"
                                alt="Beauty Sleep logo"
                                src={SVGBeautysleep.src}
                                width={SVGBeautysleep.width}
                                height={SVGBeautysleep.height}
                            />
                        }
                        link={multisiteRouteCustomersPostBeautysleep(locale)}
                        linkLabel={t('caseStudyButton')}
                    />
                </div>
            )}
        </section>
    );
};

export default WhyGetresponse;
