import { CTAStartFreeCreateAccount } from '@components/ui/design2023/CTAStartFreeCreateAccount';
import CustomLink from '@components/global/CustomLink';
import DeskoptBr from '@components/ui/DeskoptBr';
import FormattedPrice from '@components/global/FormattedPrice';
import React from 'react';
import Trans from '@components/Trans';
import clsx from 'clsx';
import dynamic from 'next/dynamic';
import styles from './PricingBanner.module.css';
import { useRouter } from 'next/router';
import useTranslation from '@hooks/useTranslation';

const MoveLogo = dynamic(() => import('./MoveLogo'), {
    ssr: true,
});
const MoveLogoPL = dynamic(() => import('./MoveLogoPL'), {
    ssr: true,
});
const FortyPercent = dynamic(() => import('./FortyPercent'), {
    ssr: true,
});

interface PricingBannerProps {
    price: number | null;
    promoPrice: number | null;
    currency: string | null;
    isPromoActive?: boolean;
    sticky?: boolean;
}

const PricingBanner = ({ price = 0, promoPrice, currency, isPromoActive = false, sticky = true }: PricingBannerProps) => {
    const { t } = useTranslation('pages/homepage/2024/v1/index');
    const { locale } = useRouter();

    return (
        <aside className={clsx(styles.banner, isPromoActive ? styles.promoBanner : '', sticky === true ? styles.sticky : '')}>
            {isPromoActive && <div className={styles.promoBox}>{locale && locale === 'pl' ? <MoveLogoPL /> : locale && ['de', 'it'].includes(locale) ? <FortyPercent /> : <MoveLogo />}</div>}
            <p>
                <Trans
                    i18nKey="pricingBanner"
                    components={{
                        price: (
                            <>
                                {isPromoActive && promoPrice ? (
                                    <>
                                        <FormattedPrice
                                            value={promoPrice}
                                            locale={locale?.replace('vn', 'vi')}
                                            currency={currency}
                                            period={t('pricingBannerPlan')}
                                            styles={{
                                                container: styles.promoPrice,
                                                currency: styles.priceCurrency,
                                                value: styles.priceValue,
                                                period: styles.pricePeriod,
                                            }}
                                        />
                                        <FormattedPrice
                                            value={price}
                                            locale={locale?.replace('vn', 'vi')}
                                            currency={currency}
                                            styles={{
                                                container: styles.oldPrice,
                                                currency: styles.oldPriceCurrency,
                                                value: styles.oldPriceValue,
                                            }}
                                        />
                                    </>
                                ) : (
                                    <FormattedPrice
                                        value={price}
                                        locale={locale?.replace('vn', 'vi')}
                                        currency={currency}
                                        period={t('pricingBannerPlan')}
                                        styles={{
                                            container: styles.price,
                                            currency: styles.priceCurrency,
                                            value: styles.priceValue,
                                            period: styles.pricePeriod,
                                        }}
                                    />
                                )}
                            </>
                        ),
                        link: <CustomLink href={{ pathname: 'pricing' }} />,
                        br: <DeskoptBr />,
                    }}
                    ns="pages/homepage/2024/v1/index"
                />
            </p>
            <CTAStartFreeCreateAccount
                params={{ version: 'centered' }}
                label={isPromoActive ? t('pricingBannerButtonVDay') : t('pricingBannerButton')}
                color={isPromoActive ? 'yellow' : 'black'}
            />
        </aside>
    );
};

export default PricingBanner;
