import styles from './G2Reviews.module.css';
import badge from './g2.png';

interface G2ReviewsProps {
    customStyles?: {
        g2reviews?: string;
    };
}

function G2Reviews({ customStyles }: G2ReviewsProps) {
    return (
        <div className={[styles.g2reviews, customStyles?.g2reviews].filter(Boolean).join(' ')}>
            <a
                href="https://www.g2.com/products/getresponse/reviews?utm_source=review-widget"
                title="Read reviews of GetResponse on G2"
                target="_blank"
            >
                <img
                    src={badge.src}
                    alt=""
                    width={badge.width / 2}
                    height={badge.height / 2}
                />
            </a>
            <p>EMAIL MARKETING INDUSTRY LEADER SINCE 1998</p>
        </div>
    );
}

export default G2Reviews;
